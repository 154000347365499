import React from 'react';
interface props {
  src?: string;
  alt: string;
  className?: string;
}
const Image = ({ src, alt, className }: props) => {
  return <img alt={alt} src={src} loading='lazy' className={className} />;
};

export default Image;
