import React, { useEffect, useState } from 'react';
import Button from 'common/components/tags/button/Button';
import styles from './Style.module.css';
import Image from 'common/components/tags/img/Img';
import LockLayer from 'common/components/UI/overlay';
import SubFilterRange from 'common/components/UI/filter/SubFilter-PriceRange';
import { Name, useSearchContext } from 'common/context/search-context';
import useHttp from 'common/hooks/useHttp';
import { error } from 'common/utils/toast';
import { useRouter } from 'next/router';
import SearchLoacation from './SearchLoacation';
import { convertSearchParams } from 'common/utils/methods';
import Typography from 'common/components/tags/typography/Typography';

const FindProperty = () => {
  const router = useRouter();
  const searchCtx = useSearchContext();
  const { loading, sendRequest } = useHttp();

  useEffect(() => {
    searchCtx?.handleSelected('');
  }, []);
  const handleSearch = () => {
    if (searchCtx?.data?.area) {
      sendRequest(
        {
          url: '/properties' + convertSearchParams(searchCtx?.data),
        },
        onSuccess
      );
    } else error('select location');
  };
  const onSuccess = (res: Responsive) => {
    searchCtx?.handleSelected('');
    searchCtx?.handleResult(res.data);
    router.push('/find-property' + convertSearchParams(searchCtx?.data));
  };

  return (
    <div className='container'>
      <LockLayer
        onClick={() => searchCtx?.handleSelected('')}
        active={
          searchCtx?.selected === Name.area || searchCtx?.selected === 'range'
        }
      />
      <div
        className={
          `relative z-[5] mx-auto mt-9 flex flex-wrap items-end justify-between gap-5 rounded-4
           bg-white p-5 shadow-shadow3 md:mt-9 md:max-w-[70vw] md:gap-3 md:p-6 lg:mt-12 lg:max-w-[68vw] lg:p-[4.2rem] xl:max-w-[72vw] xl:flex-nowrap xl:gap-10 3xl:max-w-[60vw]
     ` + styles['find-property']
        }
      >
        <div className='flex w-full flex-col justify-between gap-2 md:flex-row'>
          <div className='w-full'>
            <Typography variant='h4-card' color='primary' className='mb-2'>
              location
            </Typography>
            <SearchLoacation />
          </div>

          <div className='w-full'>
            <Typography
              variant='h4-card'
              color='primary'
              className='mb-2 capitalize'
            >
              Price Range
            </Typography>
            <SubFilterRange
              active={true}
              className='static h-[4.7rem] w-full shadow-none'
              hasTitle={false}
            />
          </div>
        </div>

        <Button
          id='find-property-btn'
          label='Search'
          iconClassName='search'
          icon_align='left'
          className='btn-search z-[-1] w-full md:w-auto'
          loading={loading}
          onClick={handleSearch}
        />
      </div>
      <div className={styles['find-property-line']}></div>
      <div
        className='hero-filer-line relative my-[5.8rem] mx-auto h-[24rem] w-[24rem] rounded-9
        bg-primary before:absolute before:right-[50%] before:top-[1.8rem] before:my-1 
        before:h-[21rem] before:w-[21rem] before:rounded-[50%] before:bg-[#FAFBFF] before:opacity-10 before:content-[""] md:hidden'
      >
        <Image
          src='/img/star-white.svg'
          alt='star-white'
          className='absolute left-[1.3rem] bottom-[6rem] h-[3rem] w-[3rem]'
        />
        <Image
          src='/img/star-outline.svg'
          alt='star-outline'
          className='absolute left-[-4rem] bottom-[2rem] h-[4rem] w-[4rem]'
        />
        <Image
          className='z-1 absolute left-[43%] top-[6%] h-[21rem] w-[21rem] rounded-full'
          src='/img/unsplash_042Srn0-82.webp'
          alt='apartment-hero'
        />
        <Image
          src='/img/widget-boy.svg'
          alt='widget-boy'
          className='] absolute right-[32%] top-[-6%]'
        />
      </div>
    </div>
  );
};

export default FindProperty;
