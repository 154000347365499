import React from 'react';
import Typography from 'common/components/tags/typography/Typography';
import { getPrice } from 'common/utils/methods';
import style from './Style.module.css';
import clsx from 'clsx';

export interface props {
  monthlyRent?: number | string;
  deposit?: number | string;
  className?: string;
}

const Pricing = ({ monthlyRent, deposit, className }: props) => {
  return (
    <>
      {monthlyRent && deposit && (
        <div className={clsx(style['pricing'], className)}>
          <Typography variant='h5-card' color='primary'>
            {getPrice(monthlyRent)}
          </Typography>
          <Typography variant='p3' color='primary' className='pt-1'>
            / mo
          </Typography>
          <Typography variant='p3' color='primary' className='pt-1 pl-1'>
            (Deposit: {getPrice(deposit)})
          </Typography>
        </div>
      )}
    </>
  );
};

export default Pricing;
