// public/schema.js

export const schema = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Apollon",
  "url": "https://apollon.uk.com",
  "logo": "https://apollon.uk.com/img/logo.png",
  "description":"A platform for convenient house rental and contract",
  "sameAs": [
    "https://www.linkedin.com/company/apollon-uk",
    "https://www.youtube.com/@apollon_uk",
    "https://www.instagram.com/apollon_uk",
    "https://twitter.com/apollon_ltd",
    "https://www.facebook.com/apollon.uk",
    "https://www.pinterest.com/apollon_uk"
  ],
  "contactPoint":{
    "@type":"ContactPoint",
    "telephone": "+44 7969 321297",
    "email":"support@apollon.uk.com.com",
    "contactType":"Customer service",
    "areaServed": "UK"
  }
}
`;
