import React from 'react';
import Button from 'common/components/tags/button/Button';
import Typography from 'common/components/tags/typography/Typography';

const Contract = () => {
  return (
    <section className=' container grid grid-cols-2 flex-col-reverse'>
      <div className=' order-last col-span-2 mt-6 md:order-first md:col-span-1 md:mt-0'>
        <img src='/img/contract.svg' alt='Sign your contracts with more confidence' />
      </div>
      <div className=' col-span-2 text-center md:col-span-1 md:text-left lg:pt-[5.7rem]'>
        <Typography tag='h2' className='leading-[110%]'>
          Sign your contracts with more confidence
        </Typography>
        <Typography
          variant='p1'
          color='gray1'
          className='py-8 md:py-4 lg:pb-6 lg:pt-[1rem] normal-case'
        >
          Small mistakes like not reading a contract thoroughly can cause huge
          problems later down the line because once a contract is signed it
          becomes legally binding. Our feature uses AI to identify clauses in
          contracts that are out of the ordinary.
        </Typography>
        <Button
          label='more about sign'
          type='link'
          target='_blank'
          path={'/more/sign-your-contracts-with-more-confidence'}
          className='w-full md:w-auto'
        />
      </div>
    </section>
  );
};

export default Contract;
