import React from 'react';
import Button from 'common/components/tags/button/Button';
import styles from './Style.module.css';
import Typography from 'common/components/tags/typography/Typography';

const Features = () => {
  return (
    <section className='container'>
      <div className='grid grid-cols-2 text-center md:text-left lg:items-center'>
        <div className={`col-span-2 md:col-span-1 md:pt-[2rem] lg:pt-0`}>
          <Typography tag='h2'>Post your rental listing</Typography>
          <Typography
            variant='p1'
            color='gray1'
            className='py-4 md:py-[1.4rem] lg:pb-6 lg:pt-[1rem] normal-case'
          >
            Our easy-to-use tools allow you to list your property where millions
            of people can view it. Plus, messaging and tenant screening are
            integrated into our listing platform to assist you in locating and
            screening tenants.
          </Typography>
          <Button
            label='post rental list'
            type='link'
            path={'/add-property'}
            className='mb-[1.6rem] w-full md:mb-0 md:w-auto'
          />
        </div>
        <div
          className={
            `col-span-2 justify-self-center md:col-span-1 md:justify-self-end ` +
            styles['line7']
          }
        >
          <img src='/img/rents.svg' alt='Post your rental listing' />
        </div>
      </div>
      <div className='mt-[6rem] grid grid-cols-2 text-center md:mt-0 md:text-left lg:items-center'>
        <div className={`col-span-2 md:col-span-1 md:pt-[4rem] lg:pt-0`}>
          <Typography tag='h2'>Protection for your deposits</Typography>
          <Typography
            variant='p1'
            color='gray1'
            className='py-4 md:py-[1.4rem] lg:pb-6 lg:pt-[1rem] normal-case'
          >
            We have an in-house photo bank. this system is designed to protect
            both the tenants and the landlords. tenants will be required to
            photo document their new property and this data will be stored on
            Apollon.
          </Typography>
          <Button
            label='About Protection'
            type='link'
            target='_blank'
            path={'/more/protection-for-your-deposits'}
            className='mb-[1.6rem] w-full md:mb-0 md:w-auto'
          />
        </div>
        <div
          className={
            `col-span-2 md:order-first md:col-span-1 ` + styles['line8']
          }
        >
          <img src='/img/TDS.svg' alt='Protection for your deposits' />
        </div>
      </div>
      <div className='grid grid-cols-2 text-center md:mt-[4.5rem] md:text-left lg:items-center'>
        <div className={`col-span-2 pt-[11rem] md:col-span-1 md:pt-0`}>
          <Typography tag='h2'>Digitise your contracts</Typography>
          <Typography
            variant='p1'
            color='gray1'
            className='py-4 md:py-[1.4rem] lg:pb-6 lg:pt-[1rem] normal-case'
          >
            Digitising contracts and collecting digital signatures from all
            parties ensures that information is properly stored and processed.
            Open banking is used for tenant income checking, verification, and
            automated secure payment processing.
          </Typography>
          <Button
            label='more about Digitise'
            type='link'
            target='_blank'
            path={'/more/digitize-your-contracts'}
            className='mb-[1.6rem] w-full md:mb-0 md:w-auto'
          />
        </div>
        <div className='col-span-2 justify-self-center md:col-span-1 md:justify-self-end'>
          <img src='/img/digitize.svg' alt='Digitise your contracts' />
        </div>
      </div>
    </section>
  );
};

export default Features;
