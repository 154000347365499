import React from 'react';
import Typography from 'common/components/tags/typography/Typography';
import clsx from 'clsx';
import style from './Style.module.css';

export interface props {
  icon: string;
  title: string;
  body: string;
  className?: string;
  iconClassName?: string;
  detail?: string;
}

const BenefitCard: React.FC<props> = ({
  icon,
  title,
  body,
  detail,
  className,
  iconClassName,
}) => {
  return (
    <div
      className={clsx(
        className,
        `w-full rounded-4 border border-[#D1D6DD] border-opacity-70 bg-white
        p-[1.4rem] text-center md:mx-auto md:max-w-full md:py-[1.4rem] md:px-3 lg:py-[4.2rem] lg:px-6`,
        style['Benefit']
      )}
    >
      <div className='flex justify-center'>
        <img src={icon} alt={title} className={iconClassName} />
      </div>

      <Typography
        variant='h4-card'
        color='gray1'
        className='pt-[1.4rem] capitalize md:pt-3 xl:pb-2'
      >
        {title}
      </Typography>

      <Typography variant='p2'>{body}</Typography>
      {detail && <Typography variant='p2'>{detail}</Typography>}
    </div>
  );
};

export default BenefitCard;
