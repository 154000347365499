import React from 'react';
import Button from 'common/components/tags/button/Button';
import styles from './Style.module.css';
import Typography from 'common/components/tags/typography/Typography';

const AddListBanner = () => {
  return (
    <div className='container'>
      <div
        className={
          `my-[7.8rem] flex flex-col-reverse 
       items-center justify-between rounded-[2.4rem] bg-blue10 py-[3.2rem]
       px-[1.6rem] md:flex-row md:px-[4.8rem] lg:rounded-[3.2rem] lg:px-[7.2rem]
       ` + styles['add-list-banner']
        }
      >
        <div className='md:pr-[5rem] xl:pr-[17.7rem]'>
          <img
            src='/img/home-addlist-banner.webp'
            alt='List your property!'
            className='md:max-h-[14.6rem] md:max-w-[30.5rem] 
            lg:max-h-[21.2rem] lg:max-w-[44.2rem]'
          />
        </div>
        <div className=' w-full text-center md:w-auto md:text-left'>
          <Typography tag='h3'>List Your Property!</Typography>
          <Typography
            variant='p1'
            color='gray1'
            className='pt-[0.8rem] pb-[3.2rem] md:py-[1.4rem] lg:py-[2.2rem]'
          >
            Our easy-to-use tools allow you to list your property where millions
            of people can view it.
          </Typography>
          <Button
            label='Add List'
            type='link'
            path='/add-property'
            iconClassName='listing text-[1.8rem]'
            variant='outLine'
            className='mb-[3.2rem] w-full md:mb-[0.5rem] md:w-auto'
          />
        </div>
      </div>
    </div>
  );
};

export default AddListBanner;
