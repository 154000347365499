import Button from 'common/components/tags/button/Button';
import React from 'react';
import styles from './Style.module.css';
import Img from 'common/components/tags/img/Img';
import Typography from 'common/components/tags/typography/Typography';

const Plan = () => {
  return (
    <section className='container mt-[2.6rem] md:mt-[4.3rem] lg:mt-[6rem]'>
      <div
        className={
          `grid grid-cols-2 items-center justify-items-end gap-0 rounded-[2.4rem] bg-primary py-[3.2rem] px-[1.6rem] md:gap-[5rem] 
      md:rounded-[1.6rem] md:py-[2rem] md:px-[3.2rem] lg:gap-[20rem] lg:rounded-[2.4rem]
       lg:py-[3.2rem] lg:px-[7.2rem] ` + styles['line9']
        }
      >
        <div className='col-span-2 text-center md:col-span-1 md:text-left'>
          <Typography tag='h3' color='white'>
            Get the most from your listings with premium
          </Typography>
          <Typography
            variant='p1'
            color='white'
            className='mt-[0.8rem] md:mt-[1.6rem] lg:mt-[2.2rem]'
          >
            Post your rental listing unlimited with a one-time payment for a certain period, you can add unlimited properties.
          </Typography>
          <Button
            label='pricing plans'
            type='link'
            path='/pricing-landlords'
            iconClassName='price text-[1.8rem]'
            className='my-[3.2rem] w-full border-white md:mb-0 md:mt-[1.6rem] md:w-auto lg:mt-[2.2rem]'
            icon_align='left'
          />
        </div>
        <div className='col-span-2 md:col-span-1'>
          <Img
            src='/img/plan.webp'
            alt='Get the most from your listings with premium'
            className='md:h-[14rem] md:w-[28rem] lg:h-[21.2rem] lg:w-[44rem]'
          />
        </div>
      </div>
    </section>
  );
};

export default Plan;
