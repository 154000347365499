import React, { memo } from 'react';
import RentCard from 'common/components/cards/Rent-Card';
import Button from 'common/components/tags/button/Button';
import Typography from 'common/components/tags/typography/Typography';
import { Pagination } from 'swiper';
import { notEmpty } from 'common/utils/methods';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'common/components/npm/skeleton/Skeleton';

interface Props {
  properties: Property[];
}

const RentList: React.FC<Props> = ({ properties }: Props) => {
  return (
    <>
      {notEmpty(properties) && (
        <section className='container'>
          <div className='text-center'>
            <Typography tag='h2'>
              Renting a house is easier than ever
            </Typography>
            <Typography
              variant='p2'
              color='black'
              className='mt-4 mb-6 lg:mb-8'
            >
              Getting signed leases and paying rent is easy with our property
              management tools.
            </Typography>
          </div>
          <>
            <Swiper
              modules={[Pagination]}
              pagination
              slidesPerView={1}
              spaceBetween={8}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1536: { slidesPerView: 3, spaceBetween: 82 },
              }}
              className='mt-4 pb-10'
            >
              {properties ? (
                <>
                  {properties?.slice(0, 3).map((item: any, index: any) => (
                    <SwiperSlide key={index}>
                      <RentCard
                        {...item}
                        className='w-full'
                        hasDetailBtn={true}
                      />
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  {[...Array(3)].map((_, index) => (
                    <SwiperSlide key={index}>
                      <Skeleton className='h-[50rem] rounded-4' />
                    </SwiperSlide>
                  ))}
                </>
              )}
            </Swiper>
          </>

          <div className=' flex justify-center'>
            <Button
              label='more property'
              type='link'
              path='/find-property?area=London&ordering=price_high'
              variant='outLine'
              className='mb-12 w-full sm:w-[31.8rem]'
            />
          </div>
        </section>
      )}
    </>
  );
};

export default memo(RentList);
