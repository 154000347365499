import React from 'react';
import Image from 'common/components/tags/img/Img';
import Typography from 'common/components/tags/typography/Typography';
import Button from 'common/components/tags/button/Button';

const HowToUse = () => {
  return (
    <section className='mb-[4.8rem] bg-white'>
      <div className='container grid grid-cols-2 items-center'>
        <div className='col-span-2 pt-7 text-center md:col-span-1 md:pt-0 md:text-left'>
          <Typography variant='h4-card'>
            How to become familiar with Apollon?
          </Typography>
          <p className='pb-4 pt-2 text-[1.5rem] text-gray1 lg:w-3/4 normal-case'>
            Here is a look at the process you will go through as a tenant,
            landlord or agent with Apollon.
          </p>
          <Button
            label='how to use'
            type='link'
            path='/guide'
            iconClassName='arrow-right'
            icon_align='right'
            className='w-full md:w-auto'
          />
        </div>
        <div className='col-span-2 flex justify-center pb-[6rem] md:col-span-1'>
          <Image
            src='/img/howToUse.svg'
            alt='How to become familiar with apollon?'
            className='max-h-[36rem]'
          />
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
