import React, { ReactNode } from 'react';
import Slider from 'react-flickity-component';
import { useWindowSize } from 'usehooks-ts';

const SliderAndList = ({
  listClassName,
  sliderClassName,
  children,
  className,
  flickityOptions = {
    pageDots: true,
    wrapAround: true,
    prevNextButtons: false,
    autoPlay: true,
    initialIndex: 1,
  },
}: {
  listClassName?: string;
  sliderClassName?: string;
  children: ReactNode;
  className?: string;
  flickityOptions?: {
    pageDots?: boolean;
    wrapAround?: boolean;
    prevNextButtons?: boolean;
    autoPlay?: boolean;
    initialIndex?: number;
    contain?: boolean;
  };
}) => {
  const { width } = useWindowSize();
  return (
    <div className={className}>
      {width > 767 ? (
        <div className={listClassName}>{children}</div>
      ) : (
        <Slider options={flickityOptions} className={sliderClassName}>
          {children}
        </Slider>
      )}
    </div>
  );
};

export default SliderAndList;
