import React, { memo, forwardRef, useState } from 'react';
import style from './Style.module.css';
import Loading, { Color } from 'common/components/UI/loading/Spin';
import clsx from 'clsx';
import { IoIosArrowDown } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';

export type props = {
  id?: string;
  type?: string;
  variant?: 'standard' | 'unset';
  name?: string;
  onChange?: (value: MyInputEvent) => void;
  onClick?: () => void;
  placeholder?: string;
  onClear?: () => void;
  className?: string;
  parentClassName?: string;
  value?: string | number | any;
  required?: boolean;
  readonly?: boolean;
  autocomplete?: string;
  dir?: string;
  error?: any;
  label?: string;
  containerClassName?: string;
  errorClassName?: string;
  labelClassName?: string;
  displayErrorLabel?: boolean;
  checked?: boolean;
  handleKeyDown?: (e: MyKeyboardInputEvent) => void;
  loading?: boolean;
  min?: string;
  max?: string;
  disabled?: boolean;
  hasBorderError?: boolean;
  iconClassName?: string;
  prefix?: string;
  prefixList?: string[];
  postfixType?: 'icon' | 'text';
  postfix?: string;
  onClickPostfix?: () => void;
  postfixClassName?: string;
  onChangePrefix?: (value: string) => void;
  dataTestid?: string;
};

const Input = forwardRef(
  (
    {
      id,
      type,
      name,
      iconClassName,
      onChange,
      checked,
      placeholder,
      className,
      parentClassName,
      value,
      autocomplete,
      label,
      labelClassName,
      required,
      readonly,
      dir,
      error,
      errorClassName,
      containerClassName,
      variant = 'standard',
      displayErrorLabel = true,
      handleKeyDown,
      onClear,
      loading,
      min,
      max,
      disabled,
      hasBorderError,
      prefixList,
      prefix,
      onChangePrefix,
      postfixType,
      postfix,
      onClickPostfix,
      postfixClassName,
      dataTestid,
    }: props,
    ref: any
  ) => {
    const [openPrefix, setOpenPrefix] = useState(false);
    return (
      <div
        className={clsx(
          containerClassName,
          style['variantContainer--' + variant]
        )}
      >
        {label && (
          <label
            className={clsx(labelClassName, style['variantLable--' + variant])}
            htmlFor={id ?? name}
          >
            {label}
          </label>
        )}
        <div
          className={clsx(
            parentClassName,
            style['variantDiv--' + variant],
            ((error && displayErrorLabel) || hasBorderError) &&
              style['variantErrorDiv--' + variant]
          )}
        >
          {iconClassName && (
            <i
              className={clsx(
                'apollon-' + iconClassName,
                'pr-[0.6rem] text-[1.9rem] text-gray3'
              )}
            />
          )}
          {Boolean(prefix) ? (
            <div className='mr-[0.6rem] flex w-[8rem] cursor-pointer select-none items-center justify-between border-r pr-[0.6rem]'>
              <span
                className='contents '
                onClick={() => setOpenPrefix(!openPrefix)}
              >
                {prefix}
              </span>
              {!openPrefix ? (
                <IoIosArrowDown
                  size={15}
                  color='#23274D'
                  onClick={() => setOpenPrefix(true)}
                />
              ) : (
                <IoMdClose
                  size={15}
                  color='#23274D'
                  onClick={() => setOpenPrefix(false)}
                />
              )}
            </div>
          ) : null}

          {openPrefix && onChangePrefix && (
            <ul className='border-card absolute top-[103%] left-0 z-10 w-[9rem] rounded-1 bg-white'>
              {prefixList?.map((item) => (
                <li
                  className='flex cursor-pointer p-2'
                  onClick={() => {
                    onChangePrefix(item);
                    setOpenPrefix(false);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}

          <input
            id={id ?? name}
            name={name}
            ref={ref}
            checked={checked}
            type={type}
            placeholder={placeholder}
            value={value}
            readOnly={readonly || false}
            onChange={onChange}
            required={required}
            min={min}
            max={max}
            autoComplete={autocomplete || 'off'}
            dir={dir}
            className={clsx(className, style['variantInput--' + variant])}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            data-testid={dataTestid || name}
          />
          {Boolean(postfixType) &&
            (postfixType == 'icon' ? (
              <i className={postfixClassName} onClick={onClickPostfix} />
            ) : null)}
          {onClear && value && (
            <>
              {loading ? (
                <Loading color={Color.gray} />
              ) : (
                <i
                  className='apollon-close-small cursor-pointer text-[1.2rem] text-[#A3AEBB]'
                  onClick={onClear}
                />
              )}
            </>
          )}
        </div>
        {error && displayErrorLabel && (
          <p
            id={'error-' + name}
            className={clsx(
              errorClassName,
              style['variantErrorInput--' + variant]
            )}
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

export default memo(Input);
