import React from 'react';
import BenefitItem from 'common/components/cards/Benefit-Card';
import Typography from 'common/components/tags/typography/Typography';
import SliderAndList from 'common/components/UI/SliderAndList';

function Features() {
  return (
    <section className='container relative z-[3] mt-[6.6rem]'>
      <div className='text-center'>
        <Typography tag='h2'>The right way to rent</Typography>
        <Typography className='py-4' variant='p1' color='black'>
          Get your rental listed. Sign a lease. Get paid
        </Typography>
      </div>
      <SliderAndList
        sliderClassName='mt-6'
        listClassName='gap-[4.8rem] grid grid-cols-3 mt-8'
      >
        <BenefitItem
          icon='/img/virtual.svg'
          title='3D Virtual tours'
          body='The 3D tour eliminates the need to visit properties, which saves both parties time and money.'
        />
        <BenefitItem
          icon='/img/legal.svg'
          title='legal contracts'
          body="With digital contracts, agents won't have to worry about inaccurately storing or processing data."
        />
        <BenefitItem
          icon='/img/automation.svg'
          title='automation and aI'
          body='When contracts are not thoroughly reviewed this can be a huge source of pain for everyone involved.'
        />
      </SliderAndList>
    </section>
  );
}

export default Features;
