import React, { useEffect, useState } from 'react';
import { getAddress } from 'common/utils/methods';

// if userType of user is agent return agent data else return profile data
export const useLandlord = (
  user: Profile | undefined | null,
  isEmptyDefault?: boolean
) => {
  const [landlord, setLandlord] = useState<User>({});
  useEffect(() => {
    if (user) {
      if (user?.userType === 'agent') {
        setLandlord({
          name:
            user.agent.name ||
            user.name ||
            (isEmptyDefault ? '' : 'Untitled agency'),
          phone:
            user.agent.phone ||
            user?.address?.phone ||
            (isEmptyDefault ? '' : 'no agency phone'),
          mobile:
            user.agent.mobile ||
            user.mobile ||
            (isEmptyDefault ? '' : 'no agency mobile'),
          email:
            user.agent.email ||
            user.email ||
            (isEmptyDefault ? '' : 'no agency email'),
          bio:
            user.agent.about ||
            user.bio ||
            (isEmptyDefault ? '' : 'no agency bio'),
          img:
            user?.agent?.logo?.downloadUrl ||
            user?.avatar?.downloadUrl ||
            '/img/default-agent-icon.svg',
          address:
            getAddress({ ...user.address, hasDetail: true }) ,
        });
      } else {
        setLandlord({
          name: user?.name || (isEmptyDefault ? '' : 'no name'),
          phone: user?.address?.phone || (isEmptyDefault ? '' : 'no phone'),
          mobile: user?.mobile || (isEmptyDefault ? '' : 'no mobile'),
          email: user?.email || (isEmptyDefault ? '' : 'no email'),
          bio: user?.bio || (isEmptyDefault ? '' : 'not bio'),
          img: user?.avatar?.downloadUrl || '/img/default-agent-icon.svg',
          address:
            getAddress({ ...user.address, hasDetail: true }) ,
        });
      }
    }
  }, [user]);
  return { landlord };
};
