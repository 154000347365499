import React, { useState, useEffect } from 'react';
import { NSU } from 'common/utils/methods';
import Typography from 'common/components/tags/typography/Typography';
import { IoIosArrowDown } from 'react-icons/io';
import clsx from 'clsx';
import { getPrice } from 'common/utils/methods';
import styles from './Style.module.css';
import { useSearchContext, Name } from 'common/context/search-context';
import LockLayer from 'common/components/UI/overlay';
import { minPriceRange, maxPriceRange } from 'common/utils/constants';

const SubFilterRange = ({
  active,
  className,
  hasTitle = true,
}: {
  active: boolean;
  className?: string;
  hasTitle?: boolean;
}) => {
  const [select, setSelect] = useState<string>('');

  const [minListPrice, setMinListPrice] = useState<number[]>(minPriceRange);
  const [maxListPrice, setMaxListPrice] = useState<number[]>(maxPriceRange);
  const searchCtx = useSearchContext();

  useEffect(() => {
    const depositMin = searchCtx?.data?.depositMin ?? 0;
    if (depositMin && depositMin !== '0') {
      let filterList = minPriceRange.filter(
        (item) => item > Number(depositMin)
      );
      setMaxListPrice(filterList);
    }
  }, [searchCtx?.data?.depositMin]);

  useEffect(() => {
    const depositMax = searchCtx?.data?.depositMax ?? 0;
    if (depositMax && depositMax !== '0') {
      let filterList = minPriceRange.filter(
        (item) => item < Number(depositMax)
      );
      setMinListPrice(filterList);
    }
  }, [searchCtx?.data?.depositMax]);

  const hadleSelect = (value: string) => {
    setSelect((old) => (old === value ? '' : value));
  };

  const activeMin = select === 'min';
  const activeMax = select === 'max';

  useEffect(() => {
    if (!searchCtx?.selected) setSelect('');
  }, [searchCtx?.selected]);

  const onChangeMinRange = (value: number) => {
    setSelect('');
    searchCtx?.onChangeData(Name.depositMin, String(value));
  };

  const onChangeMaxRange = (value: number) => {
    setSelect('');
    searchCtx?.onChangeData(Name.depositMax, String(value));
  };

  const maxValue = (
    <span className='text-[1.2rem] capitalize text-gray1'>
      {searchCtx?.data?.depositMax && searchCtx?.data?.depositMax != '0'
        ? getPrice(searchCtx?.data?.depositMax)
        : 'no max'}
    </span>
  );

  const minValue = (
    <span className='text-[1.2rem] capitalize text-gray1'>
      {searchCtx?.data?.depositMin && searchCtx?.data?.depositMin != '0'
        ? getPrice(searchCtx?.data?.depositMin)
        : 'No min'}
    </span>
  );

  return (
    <>
      <LockLayer
        onClick={() => setSelect('')}
        active={activeMin || activeMax}
        className='z-[-1]'
      />
      <div
        className={clsx(
          'left-0 z-[4] px-0 duration-300',
          styles['subLayerFilter'],
          className,
          active
            ? `max-h-[43rem] ${styles['activeSubLayerFilter']}`
            : 'max-h-0 overflow-hidden border-0 md:top-[9rem]'
        )}
      >
        <div className='border-card flex h-full w-full items-center justify-between rounded-2 bg-white py-2 px-2 xl:px-6'>
          <div className={clsx(!hasTitle && 'w-full')}>
            <div
              className='flex cursor-pointer items-center justify-between'
              onClick={() => hadleSelect('min')}
              id='min-range'
            >
              {hasTitle ? (
                <Typography variant='p6' color='black'>
                  Minimum price
                </Typography>
              ) : (
                minValue
              )}
              <IoIosArrowDown
                size={20}
                className={clsx('ml-3 duration-300', activeMin && 'rotate-180')}
              />
            </div>
            {hasTitle ? minValue : null}
          </div>

          <div
            className={clsx(
              'h-[2.5rem] border-l border-gray4',
              !hasTitle && 'mx-3'
            )}
          />
          <div className={clsx(!hasTitle && 'w-full')}>
            <div
              className='flex cursor-pointer items-center justify-between'
              onClick={() => hadleSelect('max')}
              id='max-range'
            >
              {hasTitle ? (
                <Typography variant='p6' color='black'>
                  Maximum price
                </Typography>
              ) : (
                maxValue
              )}
              <IoIosArrowDown
                size={20}
                className={clsx('ml-3 duration-300', activeMax && 'rotate-180')}
              />
            </div>
            {hasTitle ? maxValue : null}
          </div>
        </div>
        <div className='relative w-full'>
          {activeMin && (
            <ul className='shadow-shadow2_card border-card absolute top-1 max-h-[30rem] w-[50%] overflow-auto rounded-2 bg-white p-6'>
              <li
                className='cursor-pointer border-b border-gray5 pb-4'
                onClick={() => onChangeMinRange(0)}
              >
                {
                  <Typography variant='p6' color='black'>
                    No Min
                  </Typography>
                }
              </li>
              {minListPrice.map((item, index) => (
                <li
                  onClick={() => onChangeMinRange(item)}
                  className='cursor-pointer border-b border-gray5 py-4 last-of-type:border-0 last-of-type:pb-0'
                  key={index}
                  id={'min-' + item}
                >
                  <Typography variant='p6' color='black'>
                    {getPrice(item)}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
          {activeMax && (
            <ul className='shadow-shadow2_card border-card absolute left-[50%] top-1 max-h-[30rem] w-[50%] overflow-auto rounded-2 bg-white p-6'>
              <li
                className='cursor-pointer border-b border-gray5 pb-4'
                onClick={() => onChangeMaxRange(0)}
              >
                <Typography variant='p6' color='black'>
                  No Max
                </Typography>
              </li>
              {maxListPrice.map((item, index) => (
                <li
                  onClick={() => onChangeMaxRange(item)}
                  className='cursor-pointer border-b border-gray5 py-4 last-of-type:border-0 last-of-type:pb-0'
                  key={index}
                  id={'max-' + item}
                >
                  <Typography variant='p6' color='black'>
                    {getPrice(item)}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
export default SubFilterRange;
