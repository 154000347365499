import React, { useState, useEffect } from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import Typography from 'common/components/tags/typography/Typography';
import Input from 'common/components/tags/inputs/Input';
import clsx from 'clsx';
import { useSearchContext, Name } from 'common/context/search-context';
import useHttp from 'common/hooks/useHttp';
import Skeleton from 'common/components/npm/skeleton/Skeleton';
import { isEmpty, notEmpty } from 'common/utils/methods';
import LockLayer from 'common/components/UI/overlay';

const SubFilterLocation = () => {
  const [searchKey, setSearchKey] = useState<string | undefined>('');
  const searchCtx = useSearchContext();
  const { loading, sendRequest } = useHttp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [islockApi, setIsLockApi] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSearchKey(searchCtx?.data?.area);
    if (isEmpty(searchCtx?.data?.area)) {
      searchCtx?.handleLocations([]);
    }
  }, [searchCtx?.data.area]);

  const onChangeLocation = (e: MyInputEvent): void => {
    setSearchKey(e.target.value);
    setIsLoading(true);
    setIsLockApi(false);
  };

  useEffect(() => {
    if (loading) setIsLoading(true);
  }, [loading]);

  const onClear = () => {
    setSearchKey('');
    searchCtx?.onChangeData(Name.area, '');
    searchCtx?.handleLocations([]);
    setIsOpen(false);
  };

  useEffect(() => {
    let timer: any = 0;
    if (searchKey) {
      timer = setTimeout(() => {
        if (!islockApi) fetchLocation();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [searchKey]);

  const fetchLocation = () => {
    sendRequest({ url: `/locations/geocoding/search/${searchKey}` }, onSuccess);
  };
  const onSuccess = (res: Responsive) => {
    searchCtx?.handleLocations(res.data);
    setIsLoading(false);
    setIsOpen(true);
  };

  const handleClick = ({
    text,
    latitude,
    longitude,
    name,
  }: {
    text: string;
    name: string;
    latitude: number;
    longitude: number;
  }) => {
    setIsLoading(false);
    searchCtx?.handleData({
      ...searchCtx.data,
      longitude: String(longitude),
      latitude: String(latitude),
      area: text,
    });
    searchCtx?.handleSelected('');
    setSearchKey(name);
    setIsLockApi(true);
    setIsOpen(false);
  };

  return (
    <>
      <LockLayer onClick={() => setIsOpen(false)} active={isOpen} />
      <div className='relative z-[5] w-full'>
        <Input
          type='text'
          name='location'
          value={searchKey}
          onClear={onClear}
          iconClassName='search'
          containerClassName='mt-0'
          variant='unset'
          parentClassName={`bg-white border-card px-3 rounded-2 flex items-center`}
          loading={isLoading}
          className='w-full flex-grow bg-transparent p-3'
          placeholder='Search your desired location or post code'
          onChange={onChangeLocation}
        />
        {isOpen && (
          <div className=' border-card absolute mt-2 max-h-[30rem] w-full overflow-auto rounded-2 bg-white px-4 pt-4'>
            {loading ? (
              <>
                {[...Array(5)].map((_, index) => (
                  <Skeleton key={index} className='mb-4 h-7' />
                ))}
              </>
            ) : (
              <>
                {notEmpty(searchCtx?.locations) ? (
                  searchCtx?.locations?.map((item, index) => (
                    <div
                      key={index}
                      id={'location-' + index}
                      className='group grid cursor-pointer grid-cols-12 border-b border-gray5 py-4 duration-100 first-of-type:pt-0 last-of-type:border-0'
                      onClick={() => handleClick(item)}
                    >
                      <HiOutlineLocationMarker
                        className={
                          'col-span-1 group-hover:text-primary xl:mt-1'
                        }
                        color={
                          searchCtx?.data?.area === item.text
                            ? '#006FFD'
                            : '#23274D'
                        }
                        size={20}
                      />
                      <Typography
                        variant='p6'
                        color='black'
                        className={clsx(
                          'col-span-11 capitalize group-hover:text-primary',
                          searchCtx?.data?.area === item.text && 'text-primary'
                        )}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <p className='pb-4 text-center'>not found any locations</p>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default SubFilterLocation;
