import React from 'react';
import styles from './Style.module.css';
import Img from 'common/components/tags/img/Img';
import Typography from 'common/components/tags/typography/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { motion } from 'framer-motion';

export default function Index() {
  return (
    <section
      className='container mt-[0.5rem] flex 
    items-center justify-center md:mt-[1.5rem] 
    md:justify-between md:px-[6rem] lg:mt-[6.5rem] lg:px-[10rem]'
    >
      <div className='hidden md:block'>
        <div
          className={
            `relative rounded-[35px] bg-primary before:absolute before:left-[50%] before:top-[6px] before:my-1
        before:rounded-[50%] before:bg-[#FAFBFF] before:opacity-10 before:content-[""] md:h-[13.6rem] 
        md:w-[13.6rem] before:md:h-[12rem] before:md:w-[12rem] lg:h-[23rem] lg:w-[23rem] before:lg:h-[21rem] before:lg:w-[18rem] ` +
            styles['hero-left-line']
          }
        >
          <LazyLoadImage
            className='z-1 absolute right-[43%] rounded-full md:top-[5%] md:h-[12rem] md:w-[12rem] lg:top-[6%] lg:h-[20rem] lg:w-[20rem]'
            src='/img/unsplash_042Srn0-82o.webp'
            alt='villa-hero'
            effect='opacity'
          />

          <motion.img
            src='/img/widget-girl.svg'
            alt='widget-box-left'
            className='absolute left-[53%] bottom-[-3rem] md:left-[60%]'
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>
      <div className='pt-8 xl:self-start'>
        <Typography tag='h1' className={'' + styles['title-hero']}>
          Easy to find and <span className='text-primary'>rent</span>
        </Typography>
        <Typography tag='h1'>
          Easy to
          <span className='text-primary'> List</span> and rent!
        </Typography>
      </div>
      <div className='hidden md:block'>
        <div
          className={
            `hero-right-line relative rounded-[35px] bg-[#006FFD] before:absolute before:right-[50%] before:top-[6px] before:my-1
        before:rounded-[50%] before:bg-[#FAFBFF] before:opacity-10 before:content-[""] md:h-[13.6rem] 
          md:w-[13.6rem] before:md:h-[12rem] before:md:w-[12rem] lg:h-[23rem] lg:w-[23rem] before:lg:h-[21rem] before:lg:w-[18rem] ` +
            styles['hero-right-line']
          }
        >
          <Img
            src='/img/star-white.svg'
            alt='star-white'
            className='absolute md:left-[0.7rem] md:bottom-[4rem] md:h-[1.9rem] md:w-[1.9rem] 
            lg:left-[1.3rem] lg:bottom-[7rem] lg:h-[3rem] lg:w-[3rem]'
          />
          <Img
            src='/img/star-outline.svg'
            alt='star-outline'
            className='absolute md:left-[-3rem] md:bottom-[2rem] md:h-[2.5rem] md:w-[2.5rem] 
            lg:left-[-4rem] lg:bottom-[3rem] lg:h-[4rem] lg:w-[4rem]'
          />
          <LazyLoadImage
            className='z-1 absolute  left-[43%] top-[6%] rounded-full md:h-[12rem] md:w-[12rem] lg:h-[20rem] lg:w-[20rem]'
            src='/img/unsplash_042Srn0-82.webp'
            alt='apartment-hero'
            effect='opacity'
          />
          <motion.img
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            src='/img/widget-boy.svg'
            alt='widget-box-right'
            className='absolute top-[2%] md:right-[45%] lg:right-[55%] lg:top-[1%] xl:right-[42%] xl:top-[2%] 2xl:right-[55%]'
          />
        </div>
      </div>
    </section>
  );
}
