import React from 'react';
import { getAddress } from 'common/utils/methods';
import Button from 'common/components/tags/button/Button';
import clsx from 'clsx';
import Link from 'common/components/tags/link';
import Typography from '../tags/typography/Typography';
import LazyLoadImage from 'common/components/npm/lazy-load-image/Image';
import { useWindowSize } from 'usehooks-ts';
import Avatar from 'common/components/UI/avatar/Avatar';
import Pricing from '../UI/text/Pricing';
import { useLandlord } from 'common/hooks/useLandlord';
export interface props extends Property {
  hasRequestBtn?: boolean;
  hasDetailBtn?: boolean;
  className?: string;
}
function RentCard({
  hasRequestBtn,
  className,
  title,
  cover,
  user,
  id,
  address,
  deposit,
  bedNumber,
  landArea,
  furnishing,
  hasDetailBtn,
  monthlyRent,
}: props) {
  const { width } = useWindowSize();
  const { landlord } = useLandlord(user);

  const innerComponent = (
    <>
      <div>
        <LazyLoadImage
          src={cover?.downloadUrl ?? '/img/default-property.svg'}
          alt={title ?? 'cover'}
          placeholderSrc='/img/default-property.svg'
          className='h-[23rem] w-full rounded-3 object-cover'
        />
      </div>

      <div className='my-4 flex items-center text-left'>
        <Avatar
          src={landlord.img}
          placeholderSrc={'/img/default-agent-icon.svg'}
          alt={landlord.name}
        />
        <div className='pl-4'>
          <div className='webkit-line-clamp-1'>
            <Typography variant='h4-card' className='normal-case'>
              {landlord.name}
            </Typography>
          </div>
          {user?.userType == 'agent' && (
            <div className='webkit-line-clamp-1'>
              <Typography variant='p4'>{user?.agent?.about}</Typography>
            </div>
          )}
        </div>
      </div>
      <div className='webkit-line-clamp-1'>
        <Typography variant='h5-card' className='text-left'>
          {title}
        </Typography>
      </div>

      <div className='flex items-center py-[0.9rem]'>
        <i className='apollon-location text-[2rem] text-[#636464]' />
        <div className='webkit-line-clamp-1 text-left'>
          <p className='pl-[1rem] text-[1.6rem] font-normal leading-[1.8rem] text-[#636464]'>
            {getAddress(address)}
          </p>
        </div>
      </div>
      <Pricing monthlyRent={monthlyRent} deposit={deposit} />
      <div className='flex items-center justify-between pt-[0.8rem] text-[#929292]'>
        <Item
          iconCss='apollon-bed text-[2rem] pr-[0.8rem] w-[2.6rem]'
          value={bedNumber}
          label='Bed'
        />

        <Item
          iconCss='apollon-area text-[1.8rem] pr-[0.8rem] w-[2.6rem]'
          value={landArea}
          label='ft'
        />

        <Item
          iconCss='apollon-sofa text-[1.8rem] pr-[0.8rem] w-[2.6rem]'
          value={furnishing?.split('_').join(' ')}
        />
      </div>
      {hasRequestBtn && (
        <Button
          label='Request Now'
          className='mt-4 w-full'
          type='link'
          path='/request-rent'
        />
      )}
      {hasDetailBtn && (
        <Button
          label='view detail'
          className='mt-4 w-full'
          type='link'
          path={'/property-detail/' + id}
        />
      )}
    </>
  );

  return (
    <>
      {hasDetailBtn ? (
        <div
          className={clsx(
            `inline-block rounded-4 border-[0.15px] border-solid border-gray3
       border-opacity-40 bg-[#fff]  p-6 duration-300`,
            className,
            width > 1279 && 'shadow-card'
          )}
        >
          {innerComponent}
        </div>
      ) : (
        <Link
          href={'/property-detail/' + id}
          className={clsx(
            `inline-block rounded-4 border-[0.15px] border-solid border-gray3
       border-opacity-40 bg-[#fff]  p-6 duration-300`,
            className,
            width > 1279 && 'shadow-card'
          )}
        >
          {innerComponent}
        </Link>
      )}
    </>
  );
}

export default RentCard;

const Item = ({
  label,
  value,
  iconCss,
}: {
  label?: string;
  value?: string | number;
  iconCss: string;
}) => {
  return (
    <div className='flex items-center justify-between'>
      <i className={iconCss} color='#929292' />
      <span className='pr-[0.4rem]'>{value}</span>
      {Boolean(label) && <span className='grid normal-case'>{label}</span>}
    </div>
  );
};
