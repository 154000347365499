/* eslint-disable @next/next/no-img-element */
import React, { useEffect } from 'react';
import Head from 'next/head';
import Layout from 'common/components/layout';
import Hero from 'modules/landing/components/Hero';
import Filter from 'modules/landing/components/FindProperty';
import { useProfileContext } from 'common/context/profile-context';
import { isEmpty } from 'common/utils/methods';
import { useRouter } from 'next/router';
import { baseApiUrl } from 'common/utils/constants';
import BenefitList from 'modules/landing/components/BenefitList';
import AddListBanner from 'modules/landing/components/AddListBanner';
import Contract from 'modules/landing/components/Contract';
import RentList from 'modules/landing/components/RentList';
import HowToUse from 'modules/landing/components/HowToUse';
import Features from 'modules/landing/components/Features';
import Plan from 'modules/landing/components/Plan';
import Help from 'modules/landing/components/Help';
import { schema } from '../../public/schema';

interface Props {
  properties: Data<Property[]>;
}
const Home: React.FC<Props> = ({ properties }: Props) => {
  const router = useRouter();
  const profileData = useProfileContext()?.data;

  useEffect(() => {
    if (
      profileData &&
      (isEmpty(profileData?.name) || isEmpty(profileData?.mobile))
    ) {
      router.push('/welcome');
    }
  }, [profileData]);

  return (
    <>
      <Head>
        <title>Apollon - Convenient house | Rental & Contract</title>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: schema }}
        />
        <meta
          name='description'
          content='Find your ideal rental house with Apollon. Explore a wide selection of properties, connect with trusted landlords, and enjoy secure contracts for a seamless renting experience.'
        />
        <link rel='canonical' href='https://apollon.uk.com/' />

        <meta property='og:title' content='Apollon' />
        <meta
          property='og:description'
          content='Find your ideal rental house with Apollon. Explore a wide selection of properties, connect with trusted landlords, and enjoy secure contracts for a seamless renting experience.'
        />
        <meta property='og:site_name' content='Apollon' />
        <meta property='og:url' content='https://apollon.uk.com/' />
        <meta
          property='og:image'
          content='https://apollon.uk.com/img/logo.svg'
        />
        <meta property='og:locale' content='en_GB' />
        <meta property='og:image:width' content='400' />
        <meta property='og:image:height' content='400' />
      </Head>
      <Layout>
        <Hero />
        <Filter />
        <BenefitList />
        <AddListBanner />
        <Contract />
        <RentList properties={properties?.items} />
        <HowToUse />
        <Features />
        <Plan />
        <Help />
      </Layout>
    </>
  );
};

export default Home;

export async function getStaticProps() {
  try {
    const res = await fetch(
      baseApiUrl +
        `/properties?distance=2&longitude=-0.273261&latitude=51.50814&area=Acton&ordering=price_high`
    );
    const data = await res.json();

    if (!data || !data.data) {
      throw new Error('Data is empty');
    }

    // Pass data to the page via props
    return { props: { properties: data.data } };
  } catch (error) {
    console.error('Failed to fetch data:', error);
    return { props: { properties: [] } }; // Fallback to an empty array in case of error
  }
}
