import React, { useState } from 'react';
import Button from 'common/components/tags/button/Button';
import Input from 'common/components/tags/inputs/Input';
import Typography from 'common/components/tags/typography/Typography';
import useHttp from 'common/hooks/useHttp';
import { success } from 'common/utils/toast';

const Help = () => {
  const [email, setEmail] = useState('');
  const { sendRequest, loading } = useHttp();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (email)
      sendRequest(
        { url: '/newsletter/subscribe', body: { email }, method: 'POST' },
        onSuccess
      );
  };
  const onSuccess = (res: Responsive) => {
    success(res.message);
    setEmail('');
  };

  return (
    <section className='container pt-[6rem] lg:pt-[13rem]'>
      <div className='grid grid-cols-2 items-center md:gap-[5rem] lg:gap-[15rem]'>
        <div className='col-span-2 text-center md:col-span-1 md:text-left'>
          <Typography tag='h3'>Subscribe to our Newsletter!</Typography>
          <Typography variant='p1' color='black' className='pt-4 pb-8 normal-case'>
            Follow our newsletter. We will regularly update our latest projects
            and news.
          </Typography>
          <form
            onSubmit={handleSubmit}
            className='flex items-center rounded-3 border-[0.8px] border-blue20 bg-white
            px-2 py-2 md:px-4 md:py-2 lg:w-[70%] lg:px-4'
          >
            <Input
              placeholder='Enter your email address Submit'
              type='email'
              name='email'
              value={email}
              iconClassName='message text-gray3 text-[1.8rem]'
              parentClassName='bg-transparent border-0 pl-0'
              containerClassName='w-0 mt-0 grow'
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button label='submit' loading={loading} />
          </form>
        </div>
        <div className='col-span-2 mt-[2.4rem] justify-self-center md:col-span-1 md:mt-0 md:justify-self-end'>
          <img src='/img/help.svg' alt='Subscribe to our newsletter!' />
        </div>
      </div>
    </section>
  );
};

export default Help;
